<template>
    <v-card flat>
        <v-card-text class="pa-0">
            <v-data-iterator :items="fullQueryData.result_urls">
                <template v-slot:header>
                    <div style="height:56px" class="d-flex align-center">
                        <span class="primary--text font-weight-bold title">Query Files</span>
                        <v-dialog v-model="sqlDialog" max-width="500">
                            <template v-slot:activator="{ on: dialog }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn color="primary" icon @click="sqlDialog = true">
                                            <v-icon color="primary" dark v-on="{ ...tooltip, ...dialog }">mdi-eye-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View query</span>
                                </v-tooltip>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <div class="primary--text"><v-icon class="mr-1">mdi-script-text-outline</v-icon>SQL Text</div>
                                </v-card-title>
                                <v-divider class="mb-1"></v-divider>
                                <v-card-text>{{ fullQueryData ? fullQueryData.command : '' }}</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn color="primary" text @click="sqlDialog = false">close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <template v-slot:default="props">
                    <v-list two-line>
                        <div v-for="(item, index) in props.items" :key="index">
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon large>mdi-folder-zip</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a :href="item" color="secondary" v-on="on" style="text-decoration:none" class="subtitle-2 secondary--text">{{
                                                    item.split('/').pop()
                                                }}</a>
                                            </template>
                                            <span>Download</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </div>
                    </v-list>
                </template>
            </v-data-iterator>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'QueryFiles',
    data() {
        return {
            sqlDialog: false
        }
    },
    computed: {
        ...mapState('snapshotStore', ['fullQueryData'])
    }
}
</script>
