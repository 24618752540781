var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c("v-data-iterator", {
            attrs: { items: _vm.fullQueryData.result_urls },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center",
                        staticStyle: { height: "56px" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "primary--text font-weight-bold title"
                          },
                          [_vm._v("Query Files")]
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var dialog = ref.on
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var tooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.sqlDialog = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              dark: ""
                                                            }
                                                          },
                                                          Object.assign(
                                                            {},
                                                            tooltip,
                                                            dialog
                                                          )
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-eye-outline"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [_c("span", [_vm._v("View query")])]
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.sqlDialog,
                              callback: function($$v) {
                                _vm.sqlDialog = $$v
                              },
                              expression: "sqlDialog"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "div",
                                    { staticClass: "primary--text" },
                                    [
                                      _c("v-icon", { staticClass: "mr-1" }, [
                                        _vm._v("mdi-script-text-outline")
                                      ]),
                                      _vm._v("SQL Text")
                                    ],
                                    1
                                  )
                                ]),
                                _c("v-divider", { staticClass: "mb-1" }),
                                _c("v-card-text", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fullQueryData
                                        ? _vm.fullQueryData.command
                                        : ""
                                    )
                                  )
                                ]),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.sqlDialog = false
                                          }
                                        }
                                      },
                                      [_vm._v("close")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "v-list",
                      { attrs: { "two-line": "" } },
                      _vm._l(props.items, function(item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { "two-line": "" } },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c("v-icon", { attrs: { large: "" } }, [
                                      _vm._v("mdi-folder-zip")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "subtitle-2 secondary--text",
                                                            staticStyle: {
                                                              "text-decoration":
                                                                "none"
                                                            },
                                                            attrs: {
                                                              href: item,
                                                              color: "secondary"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item
                                                                .split("/")
                                                                .pop()
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Download")])]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider", { attrs: { inset: "" } })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }